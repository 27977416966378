<template>
  <div class="p-10 pr-16">
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-1/3 flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
          />
          <div class="ml-4 w-3/5">
            Commandes
          </div>
        </div>

        <div class="w-2/3">
          <div class="flex justify-end w-full">
            <div class="w-1/3">
              <inputo placeholder="Rechercher..." height="54px" :icon="icons.search" />
            </div>

            <div class="w-1/4 ml-4">
              <multiselect :icon="icons.calendar" height="54px" background="white" :icon-width="15" value-t="Période" :option="periode"/>
            </div>

            <div class="w-1/4 ml-4">
              <multiselect :icon="icons.filter" height="54px" background="white" :icon-width="25" value-t="Filtrer" :option="filtre"/>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-10 flex">
        <div class="w-1/4 p-6 bg-white rounded-5">
          <div>Total des commandes</div>
          <div>32 commandes</div>
          <div>12 CNLS</div>
          <div>12 CLS</div>
        </div>

        <div class="w-1/4 p-6 bg-white rounded-5 ml-6">
          <div>Total des commandes</div>
          <div>32 commandes</div>
          <div>12 CNLS</div>
          <div>12 CLS</div>
        </div>


        <div class="w-1/4 p-6 bg-white rounded-5 ml-6">
          <div>Total des commandes</div>
          <div>32 commandes</div>
          <div>12 CNLS</div>
          <div>12 CLS</div>
        </div>


        <div class="w-1/4 p-6 bg-white rounded-5 ml-6">
          <div>Total des commandes</div>
          <div>32 commandes</div>
          <div>12 CNLS</div>
          <div>12 CLS</div>
        </div>
      </div>

      <div class="mt-10">
        <global-view
          v-if="!reloadSession"
          @info="retourEtape"
          @user="retourUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import search from '../../assets/icons/search.svg'
import calendar from '../../assets/icons/calendar.svg'
import filter from '../../assets/icons/filter.svg'
import inputo from '../../component/helper/form/input'
import multiselect from '../../component/helper/form/multiselect'
import globalView from '../../component/cdevs/commandes/globalData'

export default {
  name: "Index",

  components: {
    globalView,
    inputo,
    multiselect
  },

  data () {
    return {
      icons: {
        back,
        search,
        calendar,
        filter
      },
      activeClient: false,
      reloadSession: false,
      selectedItem: null,
      user: null,
      periode: ['Mois en cours', '3 derniers mois', '6 derniers mois'],
      filtre: ['CSNL', 'CNLNS', 'ENCOURS', 'CLNS']
    }
  },

  created () {
    this.$store.dispatch('saveQuote', null)
    this.$store.dispatch('saveBasket', [])
  },

  methods: {
    retourClick () {
      this.$router.push('/nouveauDevis')
    },

    retourUser (answer) {
      this.user = answer
    },

    retourEtape (answer) {
      this.selectedItem = answer
      this.activeDetail = true
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
</style>
