<template>
  <div class="">
    <div class="w-full flex text-c14 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/6">
        {{ item.reference }}
      </div>

      <div class="w-1/6 flex items-center ml-4">
        <nameLabel
            :first="item.client.name"
            :second="item.client.surname"
            height="25px"
            width="25px"
            size="10px"
        />
        <div class="ml-4">
          <div class="text-c16">
            {{ item.client.name }}  {{ item.client.surname }}
          </div>
        </div>
      </div>

      <div class="w-1/6 ml-4">
            {{ new Date(item.created_at).toLocaleDateString() }}
      </div>


      <div class="w-1/6 ml-4">
        {{ item.agency.label }}
      </div>

      <div class="w-1/6 ml-4">
        {{ amount }} F
      </div>

      <div class="w-1/6 ml-4">
        0 F
      </div>

      <div class="w-1/6 ml-4">
        {{ new Date(item.expireDate).toLocaleDateString() }}
      </div>

      <div class="w-1/6 ml-4">
        <bouton
            label="CSNL"
            height="32px"
            color="#FBA705"
            background="#FFF6E6"
            radius="10px"
            size="16px"
            v-if="item.state === 'IN_PROGRESS'"
            class="w-1/2"
        />
        <bouton
            label="Acceptée"
            height="32px"
            color="#00C24E"
            background="#E5F9ED"
            radius="10px"
            size="16px"
            v-if="item.state === 'ACCEPTED'"
            class="w-1/2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/entreprise.svg'
import email from '../../../assets/icons/email.svg'
import down from '../../../assets/icons/down.svg'
import bouton from '../../helper/add/button'
import nameLabel from '../../helper/add/nameLabel'

export default {
  name: "Index",
  components: {
    bouton,
    nameLabel
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        entreprise,
        email,
        down
      },
    }
  },

  computed: {
    amount: function () {
      let amount = (this.item.financialData.totalAmount + this.item.financialData.deliveryAmount)-(this.item.financialData.discountAmount + this.item.financialData.supportAmount)
      return amount.toLocaleString()
    }
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
